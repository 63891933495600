import React, { useState, useEffect, useRef, useContext } from 'react';
import styled from '@emotion/styled';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import InputMask from 'react-input-mask';

import { addAddress, eligibilityCheck } from 'Api/addressFetchAPI';

import { DefaultContext } from 'Context/DefaultContext';
import { DispatchContext } from 'context/DispatchContext';
import { StateContext } from 'context/StateContext';

import { getPhoneMask } from 'Utils/';
import colors from 'Utils/theme';
import { minTablet, max1025 } from 'Utils/variables';

import useFormInput from 'Hooks/useFormInput';

import { StyledRow, StyledInput, StyledButtonContainer, StyledWalmartButton, StyledButtonSpinner, StyledCancelButton, StyledCheckbox } from 'Components/forms/styles';
import Label from 'Components/forms/Label';
import StateSelect from 'Components/forms/StateSelect';
import validation from 'Components/forms/validation';

const FormWrapper = styled.form`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  @media (min-width: ${max1025}) {
    flex-direction: row;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.flexRow ? 'row' : 'column')};
  flex: 0 0 100%;

  @media (min-width: ${max1025}) {
    flex: 0 0 50%;
    padding-right: ${(props) => (props.noPadding ? '0' : '40px')};

    & + & {
      padding-right: 0;
    }
  }
`;

const SuggestionContainer = styled.div`
  position: absolute;
  top: 100%;
  border-radius: 0 0 18px 18px;
  border: 1px solid ${colors.inHome.medGrey};
  border-top: 0;
  overflow: hidden;
  z-index: 666;
`;

const Suggestion = styled.div`
  padding: 15px;
  background-color: ${(props) => (props.active ? colors.inHome.softYellow : colors.white)};
`;

const StyledPhoneInput = styled(InputMask)`
  padding-right: 60px;
  font-size: 16px;
  color: ${colors.walmart.blue};
  padding: 19px;
  line-height: 1.375;
  border-style: none;
  box-shadow: ${(props) => (props.valid === 'true' ? colors.inHome.darkGrey : props.valid === null ? colors.inHome.darkGrey : colors.red[600])} 0px 0px 0px 1px inset, ${colors.inHome.darkGrey} 0px 0px 0px 0px;
  -webkit-appearance: none;
  border-radius: 0;
  width: 100%;
  height: 60px;
  -webkit-appearance: none;
  background-color: transparent;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  z-index: 2;

  &:focus,
  &:active {
    box-shadow: ${(props) => (props.disabled ? `${colors.inHome.darkGrey} 0px 0px 0px 1px inset` : `${colors.inHome.blue} 0px 0px 0px 1px, ${colors.blue[200]} 0px 0px 0px 4px`)} !important;
  }
`;

const StyledCustomInput = styled(StyledInput)`
  box-shadow: ${(props) => (props.valid === 'true' ? colors.inHome.darkGrey : props.valid === null ? colors.inHome.darkGrey : colors.red[600])} 0px 0px 0px 1px inset, ${colors.inHome.darkGrey} 0px 0px 0px 0px;
`;

const StyledShortRow = styled(StyledRow)`
  flex: 0 0 calc(50% - 5px);
  margin-right: 10px;

  & + & {
    margin-right: 0;
  }

  @media (min-width: 720px) {
    flex: 0 0 50%;
    margin-right: 0;
  }
`;

const StyledTwoColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: ${minTablet}) {
    flex-direction: row;
  }
`;

const AddressForm = ({ user, storedAddress, setSoftErrors, cancelFunction = null, ineligibleModalFunction }) => {
  const { useProd } = useContext(DefaultContext);
  const { dispatch } = useContext(DispatchContext);
  const { addressesState } = useContext(StateContext);

  const { addresses } = addressesState;

  const preferredAddressRef = useRef(null);
  const focusInputRef = useRef(null);

  useEffect(() => {
    focusInputRef && focusInputRef.current.focus();
  }, [focusInputRef]);

  const [loading, setLoading] = useState(false);
  const [usePreferredAddress, setUsePreferredAddress] = useState(true);

  const [googleAddress, setGoogleAddress] = useState({
    addressLineOne: null,
    addressLineTwo: null,
    postalCode: null,
    city: null,
    state: null,
  });
  useEffect(() => {
    googleAddress.postalCode && googleAddress.postalCode.length === 5 && postalCode.validate();
  }, [googleAddress.postalCode]);

  const [addressValid, setAddressValid] = useState(null);
  const [addressTouched, setAddressTouched] = useState(false);
  const [phoneValue, setPhoneValue] = useState('');
  const [phoneValid, setPhoneValid] = useState(null);
  const [phoneTouched, setPhoneTouched] = useState(false);
  const [phoneMask, setPhoneMask] = useState(null);

  useEffect(() => {
    if (storedAddress) {
      setGoogleAddress(storedAddress);
      storedAddress.addressLineOne && storedAddress.addressLineOne.length > 5 && setAddressValid(true);
    }
  }, [storedAddress]);

  // FIXME: Find a better way to mass validate fields in a form.
  const isFormInvalid = () => {
    return !(
      firstName.isValid &&
      firstName !== null &&
      (lastName.isValid && lastName !== null) &&
      (phoneValid && phoneValue !== null) &&
      (email.isValid && email !== null) &&
      (addressValid && googleAddress && googleAddress.addressLineOne !== null) &&
      ((cityInput.isValid && cityInput !== null) || (googleAddress && googleAddress.city)) &&
      ((stateInput.isValid && stateInput !== null) || (googleAddress && googleAddress.state)) &&
      ((postalCode.isValid && postalCode !== null) || (googleAddress && googleAddress.postalCode))
    );
  };

  const firstName = useFormInput({
    initialValue: user.first_name,
    validators: [
      validation.isNaN({ message: 'Please enter a valid first name' }), //
      validation.isRequired({ message: 'First name is required' }),
    ],
  });

  const lastName = useFormInput({
    initialValue: user.last_name,
    validators: [
      validation.isNaN({ message: 'Please enter a valid last name' }), //
      validation.isRequired({ message: 'Last name is required' }),
    ],
  });

  const email = useFormInput({
    initialValue: user.username,
    validators: [
      validation.isEmail({ message: 'Please enter a valid email' }), //
      validation.isRequired({ message: 'Email is required' }),
    ],
  });

  const address2 = useFormInput({});

  const cityInput = useFormInput({
    validators: [
      validation.isNaN({ message: 'Please enter a valid city' }), //
      validation.isRequired({ message: 'City is required' }),
    ],
    useAutoFill: true,
  });

  const stateInput = useFormInput({
    validators: [validation.isRequired({ message: 'State is required' })],
    useAutoFill: true,
  });

  const postalCode = useFormInput({
    validators: [validation.isMinLength({ message: 'Please enter a valid zip code', length: 5 }), validation.isRequired({ message: 'ZIP code is required' })],
    useAutoFill: true,
  });

  const newAddress = (address) => {
    geocodeByAddress(address).then(async (results) => {
      let storageAddress = {};

      results[0].address_components.map((item) => {
        storageAddress = Object.assign({}, storageAddress, { [item.types[0]]: item.short_name });
      });

      const { street_number, route, country, administrative_area_level_1, locality, political, postal_code } = storageAddress;

      let tempAddress = {
        addressLineOne: street_number !== undefined && street_number && route !== undefined && route && `${street_number} ${route}`,
        addressLineTwo: null,
        country: country && country === 'US' ? 'USA' : country,
        state: administrative_area_level_1 && administrative_area_level_1,
        city: (locality || political) && locality ? locality : political,
        postalCode: postal_code && postal_code,
      };

      setGoogleAddress(tempAddress);
    });
  };

  const searchOptions = {
    types: ['address'],
  };

  return (
    <FormWrapper
      onSubmit={async (e) => {
        e.preventDefault();
        setLoading(true);

        const formAddress = {
          addressLineOne: googleAddress.addressLineOne,
          addressLineTwo: address2.value,
          postalCode: googleAddress.postalCode,
          city: googleAddress.city,
          state: googleAddress.state,
          country: 'USA',
        };

        const eligibility = await eligibilityCheck(formAddress, useProd).then((data) => {
          if (typeof data.errors === 'undefined' || data.errors.length > 0) {
            setSoftErrors(data.errors || [{ message: 'Oops, something went wrong. Please try again.' }]);
            return null;
          }
          return data;
        });

        const sanitizedPhone = phoneValue.replace(/\D/g, '');

        const fullAddress = {
          name: `${firstName.value} ${lastName.value}`,
          ...formAddress,
          phone: sanitizedPhone,
          eligible: eligibility ? eligibility.data.eligible : false,
          partnership: eligibility && eligibility.data.partnership,
          storeId: eligibility ? eligibility.data.storeId : null,
          defaultPreference: usePreferredAddress,
          email: email.value,
        };

        await addAddress(fullAddress, useProd)
          .then(({ data, errors }) => {
            if (typeof errors === 'undefined' || errors.length > 0) {
              setSoftErrors(errors || [{ message: 'Oops, something went wrong. Please try again.' }]);
            }
            setLoading(false);
            const updatedAddress = Object.assign({}, fullAddress, data.address);
            const newAddresses = addresses.concat(updatedAddress);
            dispatch({ type: 'UPDATE_ADDRESSES', payload: newAddresses });
            if (eligibility && eligibility.data && eligibility.data.eligible) {
              dispatch({ type: 'SET_SELECTED_ADDRESS', payload: data.address });
            }
          })
          .then(() => {
            if (!eligibility || (eligibility && eligibility.data && !eligibility.data.eligible)) {
              ineligibleModalFunction(formAddress.postalCode);
            }
            cancelFunction();
          });
      }}
    >
      <StyledTwoColumn>
        <StyledRow>
          <Label required label={'First name'} {...firstName} />
          <StyledInput ref={focusInputRef} {...firstName} />
        </StyledRow>

        <StyledRow>
          <Label required label={'Last name'} {...lastName} />
          <StyledInput {...lastName} />
        </StyledRow>
      </StyledTwoColumn>

      <Column noPadding={true}>
        <StyledRow>
          <Label required label={'Street address'} touched={addressTouched} isValid={addressValid} errorMessage={!googleAddress.addressLineOne ? 'Address is required' : 'Enter a valid address'} />

          <PlacesAutocomplete
            shouldFetchSuggestions={googleAddress && googleAddress.addressLineOne && googleAddress.addressLineOne.length > 4}
            searchOptions={searchOptions}
            value={googleAddress.addressLineOne || ''}
            onChange={(a) => setGoogleAddress({ ...googleAddress, addressLineOne: a })}
            onSelect={(a) => newAddress(a)}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <>
                <StyledCustomInput
                  valid={addressValid !== null ? addressValid.toString() : null}
                  {...getInputProps({ onFocus: () => setAddressTouched(true), onBlur: () => (googleAddress.addressLineOne && googleAddress.addressLineOne.length > 5 ? setAddressValid(true) : setAddressValid(false)) })}
                />

                <SuggestionContainer>
                  {loading && (
                    <Suggestion>
                      <StyledButtonSpinner />
                    </Suggestion>
                  )}

                  {suggestions &&
                    suggestions.length > 0 &&
                    suggestions.map((suggestion) => (
                      <Suggestion {...getSuggestionItemProps(suggestion, {})} active={suggestion.active} key={suggestion.placeId}>
                        <span>{suggestion.description}</span>
                      </Suggestion>
                    ))}
                </SuggestionContainer>
              </>
            )}
          </PlacesAutocomplete>
        </StyledRow>
      </Column>

      <Column noPadding={true}>
        <StyledRow>
          <Label label={'Apt, suite, etc (optional)'} {...address2} />
          <StyledInput {...address2} value={address2.value ? address2.value : ''} />
        </StyledRow>
      </Column>

      <Column noPadding={true}>
        <StyledRow>
          <Label required label={'City'} {...cityInput} />
          <StyledInput onChange={(e) => setGoogleAddress({ ...googleAddress, city: e.target.value })} value={googleAddress.city || ''} {...cityInput} />
        </StyledRow>
      </Column>

      <Column noPadding={true} flexRow={true}>
        <StyledShortRow>
          <Label required label={'State'} {...stateInput} />
          <StateSelect onChange={(e) => setGoogleAddress({ ...googleAddress, state: e.target.value })} value={googleAddress.state || ''} {...stateInput} />
        </StyledShortRow>

        <StyledShortRow>
          <Label required label={'ZIP code'} {...postalCode} />
          <StyledInput maxLength="5" onChange={(e) => setGoogleAddress({ ...googleAddress, postalCode: e.target.value })} value={googleAddress.postalCode || ''} {...postalCode} />
        </StyledShortRow>
      </Column>

      <Column noPadding={true}>
        <StyledRow>
          <Label required label={'Email'} {...email} />
          <StyledInput name="email" {...email} />
        </StyledRow>
      </Column>

      <Column noPadding={true}>
        <StyledRow>
          <Label required label={'Phone number'} touched={phoneTouched} isValid={phoneValid} errorMessage={phoneValue.length === 0 ? 'Phone is required' : 'Enter a valid phone number'} />
          <StyledPhoneInput
            valid={phoneValid !== null ? phoneValid.toString() : null}
            onFocus={() => setPhoneTouched(true) + setPhoneMask(null)}
            onChange={(e) => {
              setPhoneValue(e.target.value);
              if (e.target.value[0] === '1') {
                return setPhoneValid(false);
              }
              if (e.target.value.length === 10) {
                setPhoneValid(true);
              }
            }}
            onBlur={(e) => (e.target.value.length === 10 && e.target.value[0] !== '1' ? setPhoneValid(true) + setPhoneMask(getPhoneMask(e.target.value)) : setPhoneValid(false))}
            value={phoneValue}
            mask={phoneMask}
            maskPlaceholder={null}
          />
        </StyledRow>
      </Column>

      <StyledRow style={{ marginTop: '30px' }}>
        <StyledCheckbox onChange={() => setUsePreferredAddress(!usePreferredAddress)} checked={usePreferredAddress}>
          <span
            ref={preferredAddressRef}
            role="checkbox"
            tabIndex={0}
            onKeyDown={(e) => {
              const code = e.charCode || e.keyCode;
              if (code === 32 || code === 13) {
                setUsePreferredAddress(!usePreferredAddress);
              }
            }}
          >
            Set as my preferred address
          </span>
        </StyledCheckbox>
      </StyledRow>

      <StyledButtonContainer style={{ justifyContent: 'flex-end' }}>
        <StyledCancelButton type="button" disabled={addresses?.length === 0} onClick={() => cancelFunction()}>
          Cancel
        </StyledCancelButton>
        <SubmitButton tabIndex="1" text={'Save & continue'} loading={loading} disabled={isFormInvalid()} />
      </StyledButtonContainer>
    </FormWrapper>
  );
};

export default AddressForm;

/*
  TODO: This will most likely get turned into a more composable button component with a spinner, but this is good for now.
*/

const SubmitButton = ({ text = 'Submit', loading = false, disabled = false }) => {
  return (
    <StyledWalmartButton type="submit" disabled={disabled} size="medium">
      {loading ? <StyledButtonSpinner color={colors.white} /> : text}
    </StyledWalmartButton>
  );
};
